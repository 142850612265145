import { Typography, FaceIdSvg, Modal, Mail2Svg, PhoneSvg } from "@app/components/atoms";
import { AccountDetailLayout } from "@app/components/common";
import {
  VerifyCard,
  VerifyEmail,
  VerifyPhone,
  VerificationPending,
} from "@app/components/organisms";
import { VerifyCardType } from "@app/enums/verify_account";
import { SubmitVerifyPayload } from "@app/store/services/verify-account/types";
import { useState } from "react";
import { useGetVerifyAccountQuery } from "@app/store/services/verify-account";
import { useAuth } from "@app/store/hooks";

export default function AccountVerifyCard() {
  const [step, setStep] = useState(1);
  const { profile } = useAuth();
  const { isLoading } = useGetVerifyAccountQuery(profile.id, { skip: !profile.id });
  const [payload, setPayload] = useState<SubmitVerifyPayload>({
    front_side: "",
    back_side: "",
    type: VerifyCardType.IDENTIFY_CARD,
    phone_code: "",
    email_code: "",
  });

  const onClick = (next: number, data: Partial<SubmitVerifyPayload>) => {
    setStep(next);
    setPayload((prev) => ({
      ...prev,
      ...data,
    }));
  };

  if (isLoading) return null;

  return (
    <AccountDetailLayout title="Password">
      <div className="md:w-2/3 md:mx-auto md:px-0 px-3 flex flex-row items-center pb-16">
        <div className="relative inline-flex flex-col justify-center items-center">
          <div className="rounded-full w-60 h-60 bg-blue border border-blue flex justify-center items-center">
            <FaceIdSvg />
          </div>
          <Typography
            tag="static"
            className="md:text-18 md:leading-18 text-16 leading-16 text-blue/[.8] font-light absolute -bottom-6"
          >
            ID
          </Typography>
        </div>
        <div className="border-b border-blue flex-1" />
        <div className="relative inline-flex flex-col justify-center items-center">
          <div className="rounded-full w-60 h-60 bg-white border border-blue flex justify-center items-center">
            <Mail2Svg className="fill-blue" />
          </div>
          <Typography
            tag="static"
            className="md:text-18 md:leading-18 text-16 leading-16 text-blue/[.8] font-light absolute -bottom-6"
          >
            Email
          </Typography>
        </div>
        <div className="border-b border-blue flex-1" />
        <div className="relative inline-flex flex-col justify-center items-center">
          <div className="rounded-full w-60 h-60 bg-white border border-blue flex justify-center items-center">
            <PhoneSvg />
          </div>
          <Typography
            tag="static"
            className="md:text-18 md:leading-18 text-16 leading-16 text-blue/[.8] font-light absolute -bottom-6 whitespace-nowrap"
          >
            Phone number
          </Typography>
        </div>
      </div>
      {step === 1 && <VerifyCard onClick={onClick} />}
      {step === 2 && <VerifyEmail onClick={onClick} />}
      {step > 2 && <VerifyPhone onClick={onClick} payload={payload} />}
      <Modal show={step === 4} isCustom>
        <VerificationPending onClick={onClick} />
      </Modal>
      {/* <Modal show={step === 5} isCustom>
        <TermConditions onClick={onClick} />
      </Modal> */}
    </AccountDetailLayout>
  );
}
