import { Typography } from "@app/components/atoms";

export default function AboutUs() {
  return (
    <>
      <div className="container-layout">
        <div className="px-4 md:px-0">
          <div className=" md:py-8 py-4">
            <div className="md:grid md:grid-cols-5 place-content-center justify-items-center md:mb-8 mb-4">
              <div className="md:col-start-2 md:col-span-3">
                <Typography tag="h2" className="pb-2 text-center">
                  BLIST STORY
                </Typography>
                <Typography className="text-center">{""}</Typography>
              </div>
            </div>
            <div className="grid grid-cols-11 md:gap-5 gap-2">
              <div className="col-span-2 flex flex-row items-center">
                <img
                  src="/imgs/image-10.jpg"
                  alt="aboutus 10"
                  width={193}
                  height={205}
                  className="rounded-10 w-full"
                />
              </div>
              <div className="col-span-2 flex flex-col justify-center">
                <img
                  src="/imgs/image-11.png"
                  width={193}
                  height={205}
                  alt="aboutus 11"
                  className="rounded-10 w-full md:mb-5 mb-2"
                />
                <img
                  src="/imgs/image-12.png"
                  width={193}
                  height={205}
                  alt="aboutus 12"
                  className="rounded-10 w-full"
                />
              </div>
              <div className="col-span-3">
                <img src="/imgs/image-13.jpg" alt="aboutus 13" width={318} height={481} />
              </div>
              <div className="col-span-2 flex flex-col justify-center">
                <img
                  src="/imgs/image-14.jpg"
                  width={193}
                  height={205}
                  alt="aboutus 14"
                  className="rounded-10 w-full  md:mb-5 mb-2"
                />
                <img
                  src="/imgs/image-15.jpg"
                  width={193}
                  height={205}
                  alt="aboutus 15"
                  className="rounded-10 w-full"
                />
              </div>
              <div className="col-span-2 flex flex-row items-center">
                <img
                  src="/imgs/image-16.jpg"
                  alt="aboutus 16"
                  width={193}
                  height={205}
                  className="rounded-10 w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 md:px-0" style={{ background: "#F4F4F4" }}>
        <div className="container-layout">
          <div className=" lg:py-8 py-4">
            <div className="grid lg:grid-cols-7 xl:gap-x-32">
              <div className="lg:col-span-3 md:pt-4 pb-6 md:pb-0">
                <Typography tag="h2" className="md:pb-8 pb-2">
                  Our Mission
                </Typography>
                <Typography className="pb-2">{""}</Typography>
                <Typography className="pb-8">{""}</Typography>
                <div className="grid grid-cols-7 gap-x-10">
                  <div className="col-span-6 grid grid-cols-3">
                    <div>
                      <Typography tag="h2" className="text-primary">
                        11
                      </Typography>
                      <Typography>Years</Typography>
                    </div>
                    <div>
                      <Typography tag="h2" className="text-primary">
                        275+
                      </Typography>
                      <Typography>Users</Typography>
                    </div>
                    <div>
                      <Typography tag="h2" className="text-primary">
                        100+
                      </Typography>
                      <Typography>Customers</Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:col-span-4 lg:mt-0 mt-10px">
                <img
                  className="rounded-10 w-full"
                  src="/imgs/image-17.jpg"
                  height={460}
                  width={664}
                  alt="aboutus 17"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-layout">
        <div
          className="lg:bg-cover md:pt-28 md:pb-80 md:px-0 pb-44 md:bg-top bg-center bg-no-repeat py-6 px-4"
          style={{ backgroundImage: "url(/imgs/image-18.jpg)" }}
        >
          <div className="">
            <div className="grid grid-cols-11 xl:gap-x-32">
              <div className="md:col-start-6 md:col-span-5 col-start-1 col-span-10">
                <Typography tag="h2" className="md:pb-8 mb-2">
                  Our Vision
                </Typography>
                <Typography className="pb-2">{""}</Typography>
                <Typography className="pb-2">{""}</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
