import { useController, Control } from "react-hook-form";
import { usePlacesWidget } from "react-google-autocomplete";
import { googleApiKey } from "@app/config";

import { TextField } from "../atoms";
import type { TextfieldProps } from "../atoms";

interface Props extends TextfieldProps {
  control: Control<any>;
  defaultValue?: string;
  types?: string[];
}

export const HGoogleAddress = ({
  name,
  control,
  defaultValue = "",
  types = ["address"],
  ...props
}: Props) => {
  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({
    name: name,
    control,
  });

  const onPlace = (place: any) => {
    if (place.address_components) {
      const street_address = place.address_components?.find((item: any) =>
        item.types.includes("street_number"),
      )?.long_name;
      const route = place.address_components?.find((item: any) => item.types.includes("route"))
        ?.long_name;
      const zip_code = place.address_components?.find((item: any) =>
        item.types.includes("postal_code"),
      )?.long_name;
      const state = place.address_components?.find((item: any) =>
        item.types.includes("administrative_area_level_1"),
      )?.long_name;
      const country = place.address_components?.find((item: any) => item.types.includes("country"))
        ?.long_name;
      const city = place.address_components?.find((item: any) => item.types.includes("locality"))
        ?.long_name;
      onChange({
        lat: place?.geometry?.location?.lat(),
        lng: place?.geometry?.location?.lng(),
        street_address: `${street_address ? `${street_address} ` : ""}${route || ""}`,
        zip_code: zip_code || "",
        state: state || "",
        country: country || "",
        city: city || "",
      });
    }
  };

  const { ref } = usePlacesWidget({
    apiKey: googleApiKey,
    onPlaceSelected: (place) => onPlace(place),
    inputAutocompleteValue: "country",
    options: {
      types,
    },
  });

  return (
    <TextField
      ref={ref as any}
      value={value?.address}
      name={name}
      error={errors?.addresses && "The location is required."}
      onChange={(event) => {
        onChange({
          ...value,
          address: event.target.value,
        });
      }}
      {...props}
    />
  );
};
