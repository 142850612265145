import { ReactNode } from "react";
import clsx from "clsx";

type Props = {
  tag?:
    | "p"
    | "body"
    | "body1"
    | "description"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "title"
    | "static";
  children: ReactNode;
  className?: string;
  isColor?: boolean;
};

export const Typography = ({ tag = "p", children, className, isColor = false }: Props) => {
  if (tag === "h1") {
    return (
      <h1
        className={clsx(
          "md:text-56 md:leading-56 text-38 leading-38 text-blue self-center font-Salsa",
          className,
        )}
      >
        {children}
      </h1>
    );
  }

  if (tag === "title") {
    return (
      <h1 className={clsx("md:text-30 md:leading-56 text-24 leading-38 font-Salsa", className)}>
        {children}
      </h1>
    );
  }

  if (tag === "h2") {
    return (
      <h2
        className={clsx(
          "md:text-46 md:leading-56 text-28 leading-28 md:leading-56 text-blue self-center font-Salsa",
          className,
        )}
      >
        {children}
      </h2>
    );
  }

  if (tag === "h4") {
    return (
      <h4
        className={clsx(
          "md:text-26 md:leading-31 text-22 leading-22 self-center font-Salsa",
          className,
        )}
      >
        {children}
      </h4>
    );
  }

  if (tag === "h5") {
    return (
      <h5
        className={clsx(
          "md:text-22 md:leading-27 text-20 leading-22 text-blue font-semibold self-center font-Salsa",
          className,
        )}
      >
        {children}
      </h5>
    );
  }

  if (tag === "h6") {
    return (
      <h6
        className={clsx(
          "md:text-20 md:leading-27 text-18 leading-20 font-semibold self-center",
          className,
        )}
      >
        {children}
      </h6>
    );
  }

  if (tag === "body") {
    return (
      <p
        className={clsx(
          "md:text-14 md:leading-24 text-12 leading-16 self-center",
          !isColor && "text-lightGrey",
          className,
        )}
      >
        {children}
      </p>
    );
  }

  if (tag === "body1") {
    return (
      <p
        className={clsx(
          "md:text-18 md:leading-27 text-16 leading-22 self-center",
          !isColor && "text-subtitle",
          className,
        )}
      >
        {children}
      </p>
    );
  }

  if (tag === "description") {
    return <p className={clsx("text-12 leading-14 text-lightGrey")}>{children}</p>;
  }

  if (tag === "static") {
    return <p className={className}>{children}</p>;
  }

  return (
    <p className={clsx("md:text-16 md:leading-27 text-14 leading-18 self-center", className)}>
      {children}
    </p>
  );
};
