import { BookingLoading, Button } from "@app/components/atoms";
import { AccountDetailLayout, PublicPagination } from "@app/components/common";
import { limitItem } from "@app/config";
import { InvoiceStatus } from "@app/enums";
import { useAuth } from "@app/store/hooks";
import { useFetchInvoiceQuery } from "@app/store/services/invoice";
import { useState } from "react";

export default function Invoices() {
  const { profile } = useAuth();
  const [page, setPage] = useState(1);
  const { data, isLoading } = useFetchInvoiceQuery(
    {
      user_id: profile.id,
      page,
      limit: limitItem,
    },
    {
      skip: !profile.id,
    },
  );
  return (
    <AccountDetailLayout title="Password">
      {isLoading ? (
        <BookingLoading />
      ) : (
        <>
          <table className="table-fixed w-full">
            <thead className="text-gray-400 uppercase bg-gray-700">
              <tr className="text-left">
                <th className="px-6 py-3">Experience</th>
                <th className="px-6 py-3">Amount</th>
                <th className="px-6 py-3">Status</th>
                <th className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody className="bg-white border-b">
              {data?.data.map((item, key) => (
                <tr key={key}>
                  <td className="px-6 py-4">{item.promote.experience.title}</td>
                  <td className="px-6 py-4">{item.amount}$</td>
                  <td className="px-6 py-4">{item.status}</td>
                  <td className="px-6 py-4">
                    {item.status === InvoiceStatus.PENDING && (
                      <Button variant="primary">
                        <a href={item.payment_url} target="_blank" rel="noreferrer">
                          Paynow
                        </a>
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex flex-row justify-end">
            <PublicPagination
              page={page}
              setPage={(num: number) => setPage(num)}
              total={data?.total}
            />
          </div>
        </>
      )}
    </AccountDetailLayout>
  );
}
