import { createApi } from "@reduxjs/toolkit/query/react";
import type { User } from "@app/types/user";
import moment from "moment";
import fetchBaseQuery from "../../base-query";
import type {
  SigninPayload,
  SigninResponse,
  SignupPayload,
  ForgotPasswordPayload,
  ResetPasswordPayload,
  ChangePasswordPayload,
  UpdateProfilePayload,
  GoogleAuth,
} from "./type";
import type { CommonResponse, ErrorResponse } from "../type";
import { setLogin } from "../../reducers/auth";
import { openInform } from "../../reducers/inform";
import { favoriteApi } from "../favorite";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery,
  endpoints: (builder) => ({
    signup: builder.mutation<CommonResponse<SigninResponse>, SignupPayload>({
      query(body) {
        return {
          url: `auth/signup`,
          method: "POST",
          body: {
            ...body,
            dob: body.dob && moment(body.dob).format("yyyy-MM-DD"),
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setLogin(data.data));
          dispatch(favoriteApi.endpoints.fetchFavorite.initiate({ user_id: data.data.id }));
          dispatch(openInform({ show: true, type: "success", message: "Register successfully" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
    }),
    signin: builder.mutation<CommonResponse<SigninResponse>, SigninPayload>({
      query(body) {
        return {
          url: `auth/login`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setLogin(data.data));
          dispatch(favoriteApi.endpoints.fetchFavorite.initiate({ user_id: data.data.id }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
    }),
    changePassword: builder.mutation<CommonResponse<User>, ChangePasswordPayload>({
      query(body) {
        return {
          url: `auth/change-password`,
          method: "PUT",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            openInform({ show: true, type: "success", message: "Changed password completely" }),
          );
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
    }),
    updateProfile: builder.mutation<CommonResponse<User>, UpdateProfilePayload>({
      query(body) {
        return {
          url: `auth/profile`,
          method: "PUT",
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openInform({ show: true, type: "success", message: "Updated completely" }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
    }),
    forgotPassword: builder.mutation<CommonResponse<User>, ForgotPasswordPayload>({
      query(body) {
        return {
          url: `auth/forgot_password`,
          method: "POST",
          body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;

        if (data.statusCode === 200) {
          dispatch(openInform({ show: true, type: "success", message: data?.message || "" }));
        } else {
          dispatch(openInform({ show: true, type: "error", message: data?.message || "" }));
        }
      },
    }),
    resetPassword: builder.mutation<User, ResetPasswordPayload>({
      query({ token, ...body }) {
        return {
          url: `auth/reset_password`,
          method: "POST",
          body,
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    googleAuth: builder.mutation<CommonResponse<SigninResponse>, GoogleAuth>({
      query(token) {
        return {
          url: `auth/google`,
          method: "POST",
          body: {
            token,
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setLogin(data.data));
          dispatch(favoriteApi.endpoints.fetchFavorite.initiate({ user_id: data.data.id }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
    }),
    facebookAuth: builder.mutation<CommonResponse<SigninResponse>, string>({
      query(id) {
        return {
          url: `auth/facebook`,
          method: "POST",
          body: {
            id,
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setLogin(data.data));
          dispatch(favoriteApi.endpoints.fetchFavorite.initiate({ user_id: data.data.id }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
    }),
    appleAuth: builder.mutation<CommonResponse<SigninResponse>, string>({
      query(token) {
        return {
          url: `auth/apple`,
          method: "POST",
          body: {
            token,
          },
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setLogin(data.data));
          dispatch(favoriteApi.endpoints.fetchFavorite.initiate({ user_id: data.data.id }));
        } catch (err) {
          const { error } = err as ErrorResponse;
          dispatch(openInform({ show: true, type: "error", message: error?.data?.message || "" }));
        }
      },
    }),
  }),
});

export const {
  useSigninMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useSignupMutation,
  useGoogleAuthMutation,
  useFacebookAuthMutation,
  useAppleAuthMutation,
  useChangePasswordMutation,
  useUpdateProfileMutation,
} = authApi;
