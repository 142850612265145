import {
  Typography,
  Mail2Svg,
  Button,
  TextLoading,
  ItemLoading,
  Checkbox,
} from "@app/components/atoms";
import { ExperienceRequest } from "@app/components/organisms";
import {
  useGetBookingQuery,
  useLazyGetBookingQuery,
  useUpdateBookingMutation,
} from "@app/store/services/booking";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { BookingStatus } from "@app/enums";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { UpdateBookingPayload } from "@app/store/services/booking/type";
import { HCalendar, HIncreaseField, HNotes, HTimeSlot } from "@app/components/hookform";
import { getDay } from "date-fns";
import { includes, map } from "lodash";
import { useFetchHolidaysQuery } from "@app/store/services/holiday";
import { daysNumber } from "@app/config";
import { Booking } from "@app/types";
import { useInform } from "@app/store/hooks";

type UpdateBookingForm = Omit<
  UpdateBookingPayload,
  "experience_id" | "start_date" | "end_date" | "date"
> & {
  date: Date;
};

export default function RequestToBook() {
  const { bookingId } = useParams<{ bookingId: string }>();
  const [getBooking, { isLoading }] = useLazyGetBookingQuery();
  const [updateBooking, { isLoading: isUpdating }] = useUpdateBookingMutation();
  const navigate = useNavigate();
  const [isEnable, setIsEnable] = useState(false);
  const [isShowDate, setIsShowDate] = useState(false);
  const [isShowGuest, setIsShowGuest] = useState(false);
  const [formData, setFormData] = useState<Booking | null>(null);
  const { openInform } = useInform();

  const { handleSubmit, control, ...methods } = useForm<UpdateBookingForm>({});

  useEffect(() => {
    getBooking(Number(bookingId))
      .unwrap()
      .then((data) => {
        setFormData(data);
        const splitTime = data?.availability_time.split(":");
        methods.reset({
          date: moment(data?.date).toDate(),
          adults: data?.adults,
          children: data?.children,
          infant: data?.infant,
          availability_time: moment()
            .set("hour", Number(splitTime[0]))
            .set("minute", Number(splitTime[1]))
            .format("HH:mm"),
          notes: data?.notes,
        });
      })
      .catch(() => {});
  }, []);

  const { data: holidays } = useFetchHolidaysQuery(
    { user_id: formData?.experience.user_id || 0 },
    { skip: !formData?.experience.user_id },
  );

  const filterDate = (date: Date) => {
    const day = getDay(date);
    const numDays = map(formData?.experience.available_days, (item) => daysNumber[item]);
    return includes(numDays, day);
  };

  const excludeDates = useMemo(() => {
    if (!holidays) return [];
    return holidays.map((item) => moment(item.date_time).toDate());
  }, [holidays]);

  const onUpdate = (data: {
    date?: string;
    adults?: number;
    children?: number;
    infant?: number;
  }) => {
    if (!formData) {
      return;
    }
    setFormData({
      ...formData,
      ...data,
    });
    if (data.date) {
      setIsShowDate(false);
    } else {
      setIsShowGuest(false);
    }
  };

  const onSubmit = (data: UpdateBookingForm) => {
    updateBooking({
      id: Number(bookingId),
      status: BookingStatus.COMPLETED_PAYMENT,
      adults: data.adults,
      children: data.children,
      infant: data.infant,
      date: data.date && moment(data.date).format("YYYY-MM-DD"),
      notes: data.notes,
      availability_time: data.availability_time,
    })
      .unwrap()
      .then(() => {
        openInform({ show: true, type: "success", message: "Request submitted" });
        navigate("/messages");
      })
      .catch(() => {});
  };

  return (
    <FormProvider {...methods} handleSubmit={handleSubmit} control={control}>
      <form onSubmit={handleSubmit(onSubmit)} className="px-4 md:px-0 my-8">
        <div className="">
          <Typography tag="h2" className="pb-2 mb-6">
            Request to Book
          </Typography>

          <div className="grid md:grid-cols-5 gap-5">
            <div className="md:col-span-3">
              <div className="border-b border-border6 pb-8 mb-8">
                <Typography tag="h4" className="mb-6 !font-inter font-semibold text-blue">
                  Your Experience
                </Typography>
                <div className="flex flex-row justify-between mb-6">
                  {isLoading ? (
                    <TextLoading />
                  ) : isShowDate ? (
                    <div className="my-4">
                      <HCalendar
                        control={control}
                        name="date"
                        filterDate={filterDate}
                        excludeDates={excludeDates}
                        minDate={moment().toDate()}
                      />

                      <HTimeSlot
                        control={control}
                        name="availability_time"
                        availabilityTime={formData?.experience.availability_time || []}
                      />
                    </div>
                  ) : (
                    <div>
                      <Typography tag="h6" className="font-medium text-blue">
                        Dates
                      </Typography>
                      <Typography tag="body1" className="text-blue/[.6]">
                        {moment(formData?.date).format("MMM DD")}
                      </Typography>
                    </div>
                  )}
                  {isShowDate ? (
                    <button
                      type="button"
                      onClick={() =>
                        onUpdate({
                          date: moment(methods.watch("date")).toISOString(),
                        })
                      }
                    >
                      <Typography
                        tag="h6"
                        className="font-medium text-blue underline underline-offset-2"
                      >
                        Update
                      </Typography>
                    </button>
                  ) : (
                    <button type="button" onClick={() => setIsShowDate(true)}>
                      <Typography
                        tag="h6"
                        className="font-medium text-blue underline underline-offset-2"
                      >
                        Edit
                      </Typography>
                    </button>
                  )}
                </div>
                <div className="flex flex-row justify-between">
                  <div>
                    <Typography tag="h6" className="font-medium text-blue">
                      Guests
                    </Typography>
                    {isLoading ? (
                      <TextLoading />
                    ) : isShowGuest ? (
                      <>
                        <div className="border-b border-lineBreak py-4 px-4 -mx-4">
                          <HIncreaseField label="Adults" name="adults" control={control} />
                        </div>
                        <div className="border-b border-lineBreak py-4 px-4 -mx-4">
                          <HIncreaseField
                            label="Children (age 2-12)"
                            name="children"
                            control={control}
                          />
                        </div>
                        <div className="py-4">
                          <HIncreaseField
                            label="Infant (under 2)"
                            name="infant"
                            control={control}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <Typography tag="body1" className="text-blue/[.6]">
                          {formData?.adults} adults
                        </Typography>
                        <Typography tag="body1" className="text-blue/[.6]">
                          {formData?.children} children
                        </Typography>
                        <Typography tag="body1" className="text-blue/[.6]">
                          {formData?.infant} infants
                        </Typography>
                      </>
                    )}
                  </div>
                  {isShowGuest ? (
                    <button
                      type="button"
                      onClick={() =>
                        onUpdate({
                          adults: methods.watch("adults"),
                          children: methods.watch("children"),
                          infant: methods.watch("infant"),
                        })
                      }
                    >
                      <Typography
                        tag="h6"
                        className="font-medium text-blue underline underline-offset-2"
                      >
                        Update
                      </Typography>
                    </button>
                  ) : (
                    <button type="button" onClick={() => setIsShowGuest(true)}>
                      <Typography
                        tag="h6"
                        className="font-medium text-blue underline underline-offset-2"
                      >
                        Edit
                      </Typography>
                    </button>
                  )}
                </div>
              </div>

              {/* <div className="border-b border-border6 pb-8 mb-8 ">
              <div className="flex flex-row justify-between items-center mb-6">
                <Typography tag="h4" className="!font-inter font-semibold text-blue">
                  Pay with
                </Typography>
                <div className="flex flex-row items-center">
                  <img src="/imgs/visa.png" className="mr-4" />
                  <img src="/imgs/master-card.png" className="mr-4" />
                  <img src="/imgs/paypal.png" />
                </div>
              </div>
              <div>
                <PaymentCardSelect options={options} layout="column" />
              </div>
            </div> */}

              <div className="border-b border-border6 pb-8 mb-8 ">
                <Typography tag="h2" className="!font-inter font-semibold text-blue mb-6">
                  Request for your Experience
                </Typography>
                <Typography tag="h4" className="!font-inter font-semibold text-blue mb-2">
                  Message the Creator
                </Typography>
                <div className="flex flex-row items-center justify-between">
                  <Typography tag="body1" className="text-blue/[.8]">
                    Write anything you’d like the creator to know prior to the experience.
                  </Typography>
                  <div className="rounded-5 border border-border7/[.6] p-3">
                    <a href={`/messages?userId=${formData?.experience?.user_id}`}>
                      <Mail2Svg className="fill-blue/[.62]" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="border-b border-border6 pb-8 mb-8 ">
                <Typography tag="h2" className="!font-inter font-semibold text-blue mb-6">
                  Cancellation policy
                </Typography>
                <Typography tag="body1" className="text-blue/[.8]">
                  {formData?.experience.cancellation_policy}
                </Typography>
              </div>
              <div className="border-b border-border6 pb-8 mb-8 flex flex-row">
                <div>
                  <img src="/imgs/schedule.png" className="mr-7" />
                </div>
                <div>
                  <Typography tag="body1" className="text-blue">
                    Your reservation won’t be confirmed until the Creator accept your request.
                  </Typography>
                  <Typography tag="body1" className="text-blue/[.6]">
                    Blist does not process payments at the moment. Payment will be managed directly
                    between the User and Creator. Please protect yourself and do not pay until
                    experience is confirmed.
                  </Typography>
                </div>
              </div>
              <div className="flex flex-row">
                <Checkbox
                  checked={isEnable}
                  onClick={() => {
                    setIsEnable(!isEnable);
                  }}
                />
                <Typography className="text-blue/[.7] mb-8">
                  I also agree to the updated{" "}
                  <a className="text-primary underline underline-offset-2">Terms and Conditions</a>,{" "}
                  <a className="text-primary underline underline-offset-2">
                    Payment Terms and Service
                  </a>{" "}
                  , and I acknowledge the{" "}
                  <a className="text-primary underline underline-offset-2">Privacy Policy</a>.
                </Typography>
              </div>
              <div>
                <Button
                  type="submit"
                  variant="primary"
                  layout="rectangled"
                  className="px-4"
                  isLoading={isUpdating}
                  disabled={!isEnable}
                >
                  Request to book
                </Button>
              </div>
            </div>
            <div className="md:col-span-2">
              {isLoading ? <ItemLoading /> : <ExperienceRequest />}
              <HNotes control={control} name="notes" />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
