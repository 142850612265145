import { useNavigate } from "react-router-dom";
import {
  Typography,
  IconButton,
  FillCircleFill,
  TickCircleSvg,
  FacebookSvg,
  TwitterSvg,
  InstagramSvg,
  YoutubeSvg,
  LinkedinSvg,
  SnapchatSvg,
  Calendar2Svg,
  ChartSvg,
  MsgNotifSvg,
} from "../atoms";
import { s3Url } from "@app/config";

type Props = {
  banner?: string;
};

export const BannerDetail = ({ banner }: Props) => {
  const navigate = useNavigate();
  return (
    <div className="pt-8">
      <div className="md:flex flex-row justify-between items-end mb-4">
        <Typography tag="h2">My Profile</Typography>
        {/* <div className="flex md:justify-between md:items-end flex-wrap">
          <IconButton
            className="my-2 md:text-16 text-14 bg-headerBg text-blue border border-border2 flex flex-row py-2 px-4 items-center mr-2"
            onClick={() => navigate("/messages")}
          >
            <MsgNotifSvg className="mr-2 stroke-blue" /> Inbox
          </IconButton>
          <IconButton
            className="my-2 md:text-16 text-14 bg-headerBg text-blue border border-border2 flex flex-row py-2 px-4 items-center mr-2"
            onClick={() => navigate("/calendar")}
          >
            <Calendar2Svg className="mr-2" /> Calendar
          </IconButton>
          <IconButton
            className="my-2 md:text-16 text-14 bg-headerBg text-blue border border-border2 flex flex-row py-2 px-4 items-center"
            onClick={() => navigate("/analytics")}
          >
            <ChartSvg className="mr-2" /> Data Analytics
          </IconButton>
        </div> */}
      </div>

      <div className="relative">
        <img
          src={banner ? `${s3Url}${banner}` : "/imgs/banner-4.jpg"}
          alt="banner"
          height="385"
          width="100%"
          className="rounded-10 min-h-[250px] object-cover object-center"
        />
      </div>
    </div>
  );
};

type UserIntroduceProps = {
  name?: string;
  isVerify?: boolean;
  avatar?: string;
  email?: string;
  facebook_url?: string;
  twitter_url?: string;
  instagram_url?: string;
  youtube_url?: string;
  linkedin_url?: string;
  snapchat_url?: string;
};

export const UserIntroduce = ({
  name,
  isVerify,
  avatar,
  email,
  facebook_url,
  twitter_url,
  instagram_url,
  youtube_url,
  linkedin_url,
  snapchat_url,
}: UserIntroduceProps) => {
  return (
    <div className="relative z-20 flex flex-row justify-between items-center -mt-8 md:pl-8">
      <div className="flex md:flex-row flex-col items-center md:items-end">
        <div className="mr-4">
          <div className="relative">
            <img
              src={avatar ? `${s3Url}${avatar}` : "/imgs/default-avatar.png"}
              alt="avatar"
              className="border-2 border-white shadow-avatar rounded-full md:w-150 md:h-150 w-105 h-105"
            />
            <FillCircleFill className="absolute right-0 md:right-4 bottom-4 w-15 h-15 text-16 border border-white rounded-full" />
          </div>
        </div>
        <div>
          <div className="flex flex-row items-center mb-2">
            <Typography className="text-blue mr-2" tag="h6">
              {name}
            </Typography>
            <Typography tag="body" className="text-lightGrey">
              @{name}
            </Typography>
          </div>
          <Typography tag="body" className="text-blue font-medium mb-4" isColor>
            {email}
          </Typography>
          <div className="flex flex-row justify-center items-center">
            <a href={facebook_url} target="_blank" rel="noreferrer">
              <FacebookSvg className="mr-2" />
            </a>
            <a href={twitter_url} target="_blank" rel="noreferrer">
              <TwitterSvg className="mr-2" />
            </a>
            <a href={instagram_url} target="_blank" rel="noreferrer">
              <InstagramSvg className="mr-2" />
            </a>
            <a href={youtube_url} target="_blank" rel="noreferrer">
              <YoutubeSvg className="mr-2" />
            </a>
            <a href={linkedin_url} target="_blank" rel="noreferrer">
              <LinkedinSvg className="mr-2" />
            </a>
            <a href={snapchat_url} target="_blank" rel="noreferrer">
              <SnapchatSvg />
            </a>
          </div>
        </div>
      </div>
      <div>
        {/* <div className="flex items-center text-blue md:text-12 text-10 mb-4">
          <TickCircleSvg className="mr-1" />
          Background Checked
        </div> */}
        {isVerify && (
          <div className="flex items-center text-blue md:text-12 text-10">
            <TickCircleSvg className="mr-1" />
            Identity Verified
          </div>
        )}
      </div>
    </div>
  );
};

export const UserExperience = () => {
  return (
    <div className="border border-border2 bg-headerBg rounded-20 p-6 mb-8 mt-8">
      <Typography tag="h2" className="mb-4">
        About Me
      </Typography>
      <Typography className="text-lightGrey" isColor>
        {""}
      </Typography>
    </div>
  );
};
