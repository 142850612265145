import { Typography, Star } from "../atoms";
import { useGetBookingQuery } from "@app/store/services/booking";
import { useParams } from "react-router-dom";
import { s3Url } from "@app/config";
import _ from "lodash";
import { useFetchReviewQuery } from "@app/store/services/review";
import { totalPoint } from "@app/utils";

export const ExperienceRequest = () => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const { data } = useGetBookingQuery(Number(bookingId));
  const { data: reviews } = useFetchReviewQuery(
    { experience_id: Number(data?.experience.id || "") },
    { skip: !data?.experience.id },
  );

  const nights = 1;

  return (
    <div className="border p-4 rounded-10">
      <div className="flex flex-row border-b pb-6 mb-6">
        <img
          src={
            data?.experience?.photos?.length
              ? `${s3Url}${data?.experience?.photos?.[0]}`
              : "/imgs/default-image.jpg"
          }
          alt="preview place"
          className="w-120 h-120 rounded-10 mr-4"
        />
        <div className="flex flex-col justify-between items-start flex-1">
          <div>
            <Typography tag="body1" className="text-black/[.51]">
              {_(data?.experience.category_ids || [])
                .map((item) => item.name)
                .join(", ")}
            </Typography>
            <Typography tag="h6" className="!font-normal text-blue">
              {data?.experience.title}
            </Typography>
          </div>
          <div className="flex flex-row items-center">
            <Typography tag="body1" isColor className="font-medium flex flex-row text-black">
              <Star className="mr-2" /> {totalPoint(reviews?.data)}
            </Typography>
            <Typography tag="body1" isColor className="ml-1 text-blue/[.6]">
              ({reviews?.total} reviews)
            </Typography>
          </div>
        </div>
      </div>
      <div className="border-b pb-6 mb-6">
        <Typography tag="h4" className="mb-6 !font-inter font-semibold text-blue">
          Price details
        </Typography>
        <div className="grid grid-cols-2 mb-4">
          <Typography>
            ${data?.experience.amount} x {nights} nights
          </Typography>
          <Typography className="text-right">
            ${Number(data?.experience.amount || 0) * Number(nights)}
          </Typography>
        </div>
        <div className="grid grid-cols-2 mb-4">
          <Typography>Service fee </Typography>
          <Typography className="text-right">$0</Typography>
        </div>
        <div className="grid grid-cols-2">
          <Typography>Taxes</Typography>
          <Typography className="text-right">$0</Typography>
        </div>
      </div>
      <div className="pb-3">
        <div className="grid grid-cols-2">
          <Typography className="font-semibold">
            Total <span className="underline">(USD)</span>
          </Typography>
          <Typography className="text-right font-semibold">
            ${Number(data?.experience.amount || 0) * Number(nights)}
          </Typography>
        </div>
      </div>
    </div>
  );
};
