import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  ButtonLoading,
  LocationSvg,
  ArrowSwapSvg,
  DollarCircleSvg,
  UploadMultiFileSecond,
  Button,
  Spinner,
  QuestionCircleSvg,
  Label,
} from "@app/components/atoms";
import {
  HCheckbox,
  HTextField,
  HChipButton,
  HGoogleAddresses,
  HTextArea,
  HDatePicker,
  HUserSelect,
  HRadio,
  HUpload,
  HAvailabilityTime,
  HTextEditor,
} from "@app/components/hookform";
import {
  CreatorPresence,
  ExpBusiness,
  ExpCreatorType,
  ExpKind,
  ExpLevel,
  ExpType,
  Language,
  Role,
} from "@app/enums";
import { useFetchCategoriesQuery } from "@app/store/services/category";
import {
  useCreateExperienceMutation,
  useUpdateExperienceMutation,
  useLazyGetExperienceQuery,
} from "@app/store/services/experience";
import { CreateExperienceForm, InitCreateExperience } from "@app/store/services/experience/type";
import { useFetchUserQuery } from "@app/store/services/user";
import moment from "moment";
import { convertExpToFormData } from "@app/utils";
import { useEffect, useState } from "react";
import { Tooltip } from "@app/components/atoms/Tooltip";
import { createExperienceValidation } from "@app/validations";

export default function CreateExperience() {
  const { experienceId, business } = useParams<{ experienceId: string; business: ExpBusiness }>();
  const { data: users, isLoading: isFetchingUser } = useFetchUserQuery({ role: Role.creator });
  const [createExperience, { isLoading: isCreating }] = useCreateExperienceMutation();
  const [updateExperience, { isLoading: isUpdating }] = useUpdateExperienceMutation();
  const [getExperience] = useLazyGetExperienceQuery();
  const [isLoading, setLoading] = useState(!!experienceId);

  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<CreateExperienceForm>({
    resolver: createExperienceValidation as any,
    defaultValues: InitCreateExperience,
  });
  const errorMessages = Object.keys(errors).map((key) => (errors as any)?.[key] as any);

  useEffect(() => {
    if (experienceId) {
      getExperience(experienceId)
        .unwrap()
        .then((experienceDetail) => {
          reset(convertExpToFormData(experienceDetail));
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [experienceId]);

  const { data: categoryDatas, isLoading: isCategoryLoading } = useFetchCategoriesQuery({
    is_active: true,
  });

  const onSubmit: SubmitHandler<CreateExperienceForm> = async (data) => {
    try {
      let { availability_time, addresses, ...payload } = data;

      const parseData = {
        availability_time: availability_time.map((it) => moment(it).format("HH:mm")),
        start_date: moment(payload.start_date).format("YYYY-MM-DD"),
        end_date: moment(payload.end_date).format("YYYY-MM-DD"),
        creator_ids: payload.creator_ids.map((it) => it.id),
      };

      if (experienceId) {
        await updateExperience({
          ...payload,
          ...parseData,
          addresses: addresses.map((item) => ({
            street_address: item.street_address,
            zip_code: item.zip_code,
            state: item.state,
            country: item.country,
            city: item.city,
            lat: item.lat,
            lng: item.lng,
          })),
          id: Number(experienceId),
        }).unwrap();
      } else {
        await createExperience({
          ...payload,
          ...parseData,
          addresses: addresses.map((item) => ({
            street_address: item.street_address,
            zip_code: item.zip_code,
            state: item.state,
            country: item.country,
            city: item.city,
            lat: item.lat,
            lng: item.lng,
          })),
          business: business || ExpBusiness.INDIVIDUAL,
        }).unwrap();
      }

      navigate("/creator-dashboard");
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) return <Spinner />;

  return (
    <div className="md:px-0 px-4 py-6">
      <form className=" py-12" onSubmit={handleSubmit(onSubmit)}>
        <Typography tag="h2" className="mb-8">
          Create Experience
        </Typography>
        <div className="border-b border-border2 pb-8">
          <Typography tag="h6" className="text-blue mb-4">
            What kind of Experience do you want to create
          </Typography>
          <div className="lg:w-4/5 grid md:grid-cols-4 grid-cols-2 gap-5">
            <HCheckbox
              label="Online"
              name="kind"
              value={ExpKind.ONLINE}
              control={control}
              isMulti
            />
            <HCheckbox
              label="In-person"
              name="kind"
              value={ExpKind.INPERSION}
              control={control}
              isMulti
            />
            <HCheckbox
              label="VR (Coming soon)"
              name="kind"
              disabled
              value={ExpKind.VR}
              control={control}
            />
            <HCheckbox
              label="Metaverse (Coming soon)"
              name="kind"
              disabled
              value={ExpKind.METAVERSE}
              control={control}
            />
          </div>
          <div className="md:w-1/2 mt-3">
            {watch("kind")?.includes(ExpKind.ONLINE) && (
              <HTextField
                variant="outlined-black"
                border="full"
                name="platform"
                placeholder="Enter platform here"
                label="Platform"
                control={control}
              />
            )}
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <div className="md:w-1/2">
            <HTextField
              variant="outlined-black"
              border="full"
              name="title"
              placeholder="Enter title here"
              label="Experience Title"
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4">
            Choose Category
          </Typography>
          <div className="flex flex-row flex-wrap">
            {isCategoryLoading ? (
              <ButtonLoading />
            ) : (
              categoryDatas?.map((item, index) =>
                item.is_all ? null : (
                  <div key={index} className="mr-4 mb-4">
                    <HChipButton
                      variant="slide"
                      className="px-3"
                      name="category_ids"
                      value={item.id}
                      control={control}
                      type="button"
                      isMulti
                    >
                      {item.name}
                    </HChipButton>
                  </div>
                ),
              )
            )}
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <div className="grid md:grid-cols-2">
            <div className="mb-6 md:mb-0">
              <Typography tag="h6" className="text-blue mb-8">
                Type{" "}
                <Tooltip id="willing-to-travel" content="123" className="inline-block ml-1">
                  <QuestionCircleSvg />
                </Tooltip>
              </Typography>
              <div className="md:w-2/3 flex flex-row items-center justify-between">
                <HCheckbox label="Private" name="type" value={ExpType.PRIVATE} control={control} />
                <HCheckbox label="Group" name="type" value={ExpType.GROUP} control={control} />
                <HCheckbox label="Both" name="type" value={ExpType.BOTH} control={control} />
              </div>
            </div>
            <div>
              <Typography tag="h6" className="text-blue mb-8">
                Locations
              </Typography>
              <HGoogleAddresses
                variant="outlined-black"
                border="full"
                name="addresses"
                placeholder="Enter location here"
                endIcon={<LocationSvg />}
                control={control}
                className="flex flex-row items-center mb-2"
              />
            </div>
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4 flex">
            Willing to Travel{" "}
            <Tooltip id="willing-to-travel" content="123" className="inline-block ml-1">
              <QuestionCircleSvg />
            </Tooltip>
          </Typography>
          <div className="md:w-1/4 grid grid-cols-2 gap-5 mb-4">
            <HCheckbox label="Yes" name="is_willing_to_travel" value={true} control={control} />
            <HCheckbox label="No" name="is_willing_to_travel" value={false} control={control} />
          </div>
          <div className="md:w-1/2">
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              control={control}
              name="willing_to_travel"
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4">
            Languages Offered:{" "}
            <Tooltip id="willing-to-travel" content="123" className="inline-block ml-1">
              <QuestionCircleSvg />
            </Tooltip>
          </Typography>
          <div className="lg:w-2/3 grid md:grid-cols-4 grid-cols-2 gap-5">
            <HCheckbox
              label="English"
              name="language_offered"
              value={Language.ENGLISH}
              control={control}
              isMulti
            />
            <HCheckbox
              label="Catala"
              name="language_offered"
              value={Language.CATALONIA}
              control={control}
              isMulti
            />
            <HCheckbox
              label="Español"
              name="language_offered"
              value={Language.SPAIN}
              control={control}
              isMulti
            />
            <HCheckbox
              label="Français"
              name="language_offered"
              value={Language.FRANCE}
              control={control}
              isMulti
            />
            <HCheckbox
              label="Italiano"
              name="language_offered"
              value={Language.ITALY}
              control={control}
              isMulti
            />
            <HCheckbox
              label="中国人"
              name="language_offered"
              value={Language.CHINA}
              control={control}
              isMulti
            />
            <HCheckbox
              label="日本語"
              name="language_offered"
              value={Language.JAPAN}
              control={control}
              isMulti
            />
            <HCheckbox
              label="Português"
              name="language_offered"
              value={Language.PORTUGAL}
              control={control}
              isMulti
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h2" className="mb-8">
            Schedule
          </Typography>
          <Typography tag="h6" className="text-blue mb-4">
            Does Your Experience Have a Start and End Date?{" "}
            <Tooltip
              id="start-end-date"
              content="Select YES to set Experience start/end dates,<br /> or NO to keep the Experience active until manually deactivated."
              className="inline-block ml-1"
            >
              <QuestionCircleSvg />
            </Tooltip>
          </Typography>
          <div className="md:w-1/5 grid grid-cols-2 gap-5 mb-4">
            <HRadio label="Yes" name="is_date" control={control} value={true} />
            <HRadio label="No" name="is_date" control={control} value={false} />
          </div>

          {watch("is_date") && (
            <div className="flex flex-row justify-between items-center lg:w-2/5 md:w-1/2">
              <HDatePicker
                label="Start Date"
                variant="outlined-black"
                layout="rounded"
                control={control}
                name="start_date"
                showYearDropdown
              />
              <ArrowSwapSvg className="mx-6 mt-6" />
              <HDatePicker
                label="End Date"
                variant="outlined-black"
                layout="rounded"
                control={control}
                name="end_date"
                showYearDropdown
              />
            </div>
          )}
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4">
            Experience Duration (mins){" "}
            <Tooltip id="willing-to-travel" content="123" className="inline-block ml-1">
              <QuestionCircleSvg />
            </Tooltip>
          </Typography>
          <div className="flex flex-row justify-between items-center lg:w-2/5 md:w-1/2">
            <HTextField
              border="full"
              placeholder="Enter duration here"
              variant="outlined-black"
              control={control}
              name="duration"
              type="number"
              min={0}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4">
            Experience Available Times{" "}
            <Tooltip id="willing-to-travel" content="123" className="inline-block ml-1">
              <QuestionCircleSvg />
            </Tooltip>
          </Typography>
          <HAvailabilityTime
            name="availability_time"
            control={control}
            className="flex flex-row items-center lg:w-2/5 md:w-1/2 mb-2"
          />
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4">
            Select Available Day(s) of Week{" "}
            <Tooltip id="willing-to-travel" content="123" className="inline-block ml-1">
              <QuestionCircleSvg />
            </Tooltip>
          </Typography>
          <div className="lg:w-3/4 grid md:grid-cols-7 grid-cols-2 gap-5">
            <HCheckbox isMulti label="Mon" name="available_days" value="mon" control={control} />
            <HCheckbox isMulti label="Tue" name="available_days" value="tue" control={control} />
            <HCheckbox isMulti label="Wed" name="available_days" value="wed" control={control} />
            <HCheckbox isMulti label="Thu" name="available_days" value="thu" control={control} />
            <HCheckbox isMulti label="Fri" name="available_days" value="fri" control={control} />
            <HCheckbox isMulti label="Sat" name="available_days" value="sat" control={control} />
            <HCheckbox isMulti label="Sun" name="available_days" value="sun" control={control} />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <div className="md:w-1/2">
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              label="Summary"
              subtitle="(50 words max)"
              control={control}
              name="summary"
              maxLength={50}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <div className="md:w-1/2">
            <HTextEditor
              placeholder="Enter detail here"
              label="Description"
              control={control}
              name="description"
              maxCharCount={500}
              subtitle="(500 words max)"
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <div className="md:w-1/2">
            <HTextEditor
              placeholder="Enter detail here"
              label="Atmosphere/Location"
              subtitle="(250 words max)"
              control={control}
              name="atmosphere"
              maxCharCount={250}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h2" className="mb-8">
            Creator Type
          </Typography>
          <Typography tag="h6" className="text-blue mb-4">
            Are You creating alone or Collaboration?
          </Typography>
          <div className="lg:w-2/3 grid md:grid-cols-2 gap-5 mb-4">
            <HCheckbox
              label="Individual"
              name="creator_type"
              value={ExpCreatorType.INDIVIDUAL}
              control={control}
            />
            <HCheckbox
              label="Collab With Someone"
              name="creator_type"
              value={ExpCreatorType.COLLABORATING}
              control={control}
            />
          </div>
          {watch("creator_type") === ExpCreatorType.COLLABORATING && (
            <>
              <Typography className="text-blue mb-4" isColor>
                Search creator you want to collaborate with.{" "}
                <Tooltip
                  id="willing-to-travel"
                  content="The member you select will have booking notifications sync'd to their calendar for this experience"
                  className="inline-block ml-1"
                >
                  <QuestionCircleSvg />
                </Tooltip>
              </Typography>
              <div className="md:w-1/2 mb-4">
                <HUserSelect
                  options={users?.data.map((item) => ({
                    value: item.id,
                    label: `${item.first_name} ${item.last_name}`,
                  }))}
                  isMulti
                  menuIsOpen={true}
                  name="creator_ids"
                  control={control}
                  isLoading={isFetchingUser}
                />
              </div>
            </>
          )}
        </div>

        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4">
            Creator Presence
          </Typography>
          <div className="lg:w-2/3 grid md:grid-cols-2 gap-5">
            <HCheckbox
              label="Customers Will Enjoy Experience Alone"
              name="creator_presence"
              value={CreatorPresence.ENJOY_ALONE}
              control={control}
            />
            <HCheckbox
              label="Creator Will Be Present"
              name="creator_presence"
              value={CreatorPresence.PRESENT}
              control={control}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <div className="md:w-1/2">
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter here"
              label="Description"
              subtitle="(100 words max)"
              control={control}
              name="creator_provide"
              maxLength={100}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <div className="md:w-1/2">
            <HTextField
              variant="outlined-black"
              border="full"
              name="guest_bring"
              placeholder="Enter here"
              label="What Are Guest Required to Bring?"
              control={control}
              subtitle="(100 words max)"
              maxLength={100}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <div className="md:w-1/2">
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              label="Enter Clothing Recommendation"
              subtitle="(100 words max)"
              name="clothing_recommendation"
              control={control}
              maxLength={100}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4">
            Enter Age Limit of Guest
          </Typography>
          <div className="flex flex-row justify-between items-center lg:w-2/5 md:w-1/2">
            <HTextField
              label="From"
              variant="outlined-black"
              border="full"
              control={control}
              name="age_min"
              type="number"
              min={0}
            />
            <ArrowSwapSvg className="mx-6 mt-6" />
            <HTextField
              label="To"
              variant="outlined-black"
              border="full"
              name="age_max"
              control={control}
              type="number"
              min={0}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4">
            Enter Guest Limit
          </Typography>
          <div className="flex flex-row justify-between items-center lg:w-2/5 md:w-1/2">
            <HTextField
              label="From"
              variant="outlined-black"
              border="full"
              control={control}
              name="guest_min"
              type="number"
              min={0}
            />
            <ArrowSwapSvg className="mx-6 mt-6" />
            <HTextField
              label="To"
              variant="outlined-black"
              border="full"
              name="guest_max"
              control={control}
              type="number"
              min={0}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4">
            Enter Experience Amount
          </Typography>
          <Typography tag="h6" className="text-blue mb-4">
            Free
          </Typography>
          <div className="md:w-1/5 grid grid-cols-2 gap-5 mb-4">
            <HRadio label="Yes" name="is_free" control={control} value={true} />
            <HRadio label="No" name="is_free" control={control} value={false} />
          </div>
          {!watch("is_free") && (
            <div className="md:w-1/4">
              <HTextField
                variant="outlined-black"
                border="full"
                placeholder="Enter Amount Here"
                startIcon={<DollarCircleSvg className="stroke-black w-25 h-25 ml-2" />}
                name="amount"
                type="number"
                min={0}
                control={control}
              />
            </div>
          )}
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4">
            Pet Allowed?
          </Typography>
          <div className="md:w-1/5 grid grid-cols-2 gap-5">
            <HRadio label="Yes" name="pet_allowed" control={control} value={true} />
            <HRadio label="No" name="pet_allowed" control={control} value={false} />
          </div>
          {watch("pet_allowed") && (
            <div className="mt-2 md:w-1/2">
              <HTextField
                border="full"
                variant="outlined-black"
                control={control}
                name="pet_description"
                subtitle="(50 words max)"
                maxLength={50}
              />
            </div>
          )}
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4">
            Handicap Accessible?
          </Typography>
          <div className="md:w-1/5 grid grid-cols-2 gap-5">
            <HRadio label="Yes" name="handicap_accessible" control={control} value={true} />
            <HRadio label="No" name="handicap_accessible" control={control} value={false} />
          </div>
          <div className="mt-2 md:w-1/2">
            <HTextField
              border="full"
              variant="outlined-black"
              control={control}
              name="handicap_accessible_description"
              subtitle="(50 words max)"
              maxLength={50}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <Typography tag="h6" className="text-blue mb-4">
            Activity Level Expected?
          </Typography>
          <div className="lg:w-5/6 grid md:grid-cols-6 grid-cols-2 gap-5">
            <HRadio label="None" name="level_expected" control={control} value={ExpLevel.NONE} />
            <HRadio
              label="Moderate"
              name="level_expected"
              control={control}
              value={ExpLevel.MODERATE}
            />
            <HRadio
              label="Strenuous"
              name="level_expected"
              control={control}
              value={ExpLevel.STRENUOUS}
            />
            <HRadio
              label="Extreme"
              name="level_expected"
              control={control}
              value={ExpLevel.EXTREME}
            />
            <HRadio label="Light" name="level_expected" control={control} value={ExpLevel.LIGHT} />
            <HRadio
              label="N/A"
              name="level_expected"
              control={control}
              value={ExpLevel.NOT_AVAILABLE}
            />
          </div>
        </div>
        <div className="border-b border-border2 py-4">
          <div className="md:w-1/2">
            <HTextArea
              border="circle"
              variant="outlined"
              placeholder="Enter detail here"
              label="Cancellation Policy"
              subtitle="(250 words max)"
              control={control}
              name="cancellation_policy"
              maxLength={250}
            />
          </div>
        </div>
        <div className="py-8">
          <Typography tag="h6" className="text-blue mb-4">
            Social links:
          </Typography>
          <div className="grid md:grid-cols-2 gap-5">
            <HTextField
              variant="outlined-black"
              border="full"
              name="youtube_url"
              placeholder="Youtube"
              label="Youtube"
              control={control}
            />
            <HTextField
              variant="outlined-black"
              border="full"
              name="tiktok_url"
              placeholder="Tiktok"
              label="Tiktok"
              control={control}
            />
            <HTextField
              variant="outlined-black"
              border="full"
              name="instagram_url"
              placeholder="Instagram"
              label="Instagram"
              control={control}
            />
            <HTextField
              variant="outlined-black"
              border="full"
              name="x_url"
              placeholder="X link"
              label="X link"
              control={control}
            />
          </div>
        </div>
        <div className="py-8">
          <Typography tag="h6" className="text-blue mb-4">
            Please Add Photos/Videos
          </Typography>
          <div className="grid md:grid-cols-2 gap-5">
            <HUpload control={control} imageClass="!h-200" name="photos" multiple maxFile={10}>
              <UploadMultiFileSecond
                text="Please upload a maximum of 10 photos"
                subtext="JPG, PNG or PDF, file size no more than 10MB"
              />
            </HUpload>
            <HUpload control={control} imageClass="!h-200" name="videos" multiple maxFile={2}>
              <UploadMultiFileSecond
                text="Please upload a maximum of 2 videos"
                subtext="MP4, MOV or AVI. file size no more than 10MB"
              />
            </HUpload>
          </div>
        </div>
        <Button type="submit" variant="primary" isLoading={isCreating || isUpdating}>
          Submit
        </Button>
        {errorMessages.map((it, key) => (
          <Label variant="error" key={key}>
            {it.message}
          </Label>
        ))}
      </form>
    </div>
  );
}
