import { ReactComponent as Fb } from "../../assets/icons/fb.svg";
import { ReactComponent as Ins } from "../../assets/icons/instagram.svg";
import { ReactComponent as Twi } from "../../assets/icons/twitter.svg";
import { ReactComponent as Cert } from "../../assets/icons/cert.svg";
import { ReactComponent as Hambuger } from "../../assets/icons/hambuger.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as IconHeart } from "../../assets/icons/icon-heart.svg";
import { ReactComponent as LeftButton } from "../../assets/icons/left-button.svg";
import { ReactComponent as RightButton } from "../../assets/icons/right-button.svg";
import { ReactComponent as Rocket } from "../../assets/icons/rocket.svg";
import { ReactComponent as Fire } from "../../assets/icons/fire.svg";
import { ReactComponent as RoundedUp } from "../../assets/icons/rounded-up.svg";
import { ReactComponent as Image } from "../../assets/icons/image.svg";
import { ReactComponent as Link } from "../../assets/icons/link.svg";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { ReactComponent as HeartOutline } from "../../assets/icons/heart-outline.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as Notification } from "../../assets/icons/notification.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg";
import { ReactComponent as DollarCircle } from "../../assets/icons/dollar-circle.svg";
import { ReactComponent as Edit } from "../../assets/icons/edit.svg";
import { ReactComponent as Global } from "../../assets/icons/global.svg";
import { ReactComponent as Profile } from "../../assets/icons/profile.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/send.svg";
import { ReactComponent as Paperclip } from "../../assets/icons/paperclip.svg";
import { ReactComponent as ThreeDot } from "../../assets/icons/three-dot.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ThreeSquare } from "../../assets/icons/3square.svg";
import { ReactComponent as ChefHat } from "../../assets/icons/chef-hat.svg";
import { ReactComponent as Drink } from "../../assets/icons/drink.svg";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";
import { ReactComponent as HandPlant } from "../../assets/icons/hand-plant.svg";
import { ReactComponent as List } from "../../assets/icons/list.svg";
import { ReactComponent as Lovely } from "../../assets/icons/lovely.svg";
import { ReactComponent as Map } from "../../assets/icons/map.svg";
import { ReactComponent as MountainSnow } from "../../assets/icons/mountain-snow.svg";
import { ReactComponent as CloseSquare } from "../../assets/icons/close-square.svg";
import { ReactComponent as CalendarField } from "../../assets/icons/calendar-field.svg";
import { ReactComponent as Location } from "../../assets/icons/location.svg";
import { ReactComponent as RadioChecked } from "../../assets/icons/radio-checked.svg";
import { ReactComponent as RadioUncheck } from "../../assets/icons/radio-uncheck.svg";
import { ReactComponent as ArrowSwap } from "../../assets/icons/arrow-swap-horizontal.svg";
import { ReactComponent as UploadCloud } from "../../assets/icons/feather_upload-cloud.svg";
import { ReactComponent as Facebook } from "../../assets/icons/_Facebook.svg";
import { ReactComponent as Instagram } from "../../assets/icons/_Instagram.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/_Linkedin.svg";
import { ReactComponent as Snapchat } from "../../assets/icons/_Snapchat.svg";
import { ReactComponent as Twitter } from "../../assets/icons/_Twitter.svg";
import { ReactComponent as Youtube } from "../../assets/icons/_YouTube.svg";
import { ReactComponent as EditUnderline } from "../../assets/icons/edit_underline.svg";
import { ReactComponent as PlusStatic } from "../../assets/icons/plus.svg";
import { ReactComponent as LightBulb } from "../../assets/icons/lightbulb.svg";
import { ReactComponent as TickCircle } from "../../assets/icons/tick-circle.svg";
import { ReactComponent as Calendar2 } from "../../assets/icons/calendar-2.svg";
import { ReactComponent as Chart } from "../../assets/icons/chart.svg";
import { ReactComponent as MsgNotif } from "../../assets/icons/message-notif.svg";
import { ReactComponent as More } from "../../assets/icons/more.svg";
import { ReactComponent as Announcement } from "../../assets/icons/announcement.svg";
import { ReactComponent as Trash } from "../../assets/icons/trash.svg";
import { ReactComponent as CloseCircle } from "../../assets/icons/close-circle.svg";
import { ReactComponent as CloseCircle2 } from "../../assets/icons/close-circle-2.svg";
import { ReactComponent as QuestionCircle } from "../../assets/icons/question-circle.svg";
import { ReactComponent as SmsEdit } from "../../assets/icons/sms-edit.svg";
import { ReactComponent as CalendarEdit } from "../../assets/icons/calendar-edit.svg";
import { ReactComponent as AddSquare } from "../../assets/icons/add-square.svg";
import { ReactComponent as MinusSquare } from "../../assets/icons/minus-square.svg";
import { ReactComponent as AddCircle } from "../../assets/icons/add-circle.svg";
import { ReactComponent as DirectUp } from "../../assets/icons/direct-up.svg";
import { ReactComponent as Message2 } from "../../assets/icons/messages-2.svg";
import { ReactComponent as Sms } from "../../assets/icons/sms.svg";
import { ReactComponent as Call } from "../../assets/icons/call.svg";
import { ReactComponent as DocumentText } from "../../assets/icons/document-text.svg";
import { ReactComponent as Element } from "../../assets/icons/element.svg";
import { ReactComponent as Home } from "../../assets/icons/home.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as Money } from "../../assets/icons/money.svg";
import { ReactComponent as Setting } from "../../assets/icons/setting.svg";
import { ReactComponent as Star2 } from "../../assets/icons/star2.svg";
import { ReactComponent as Star3 } from "../../assets/icons/star3.svg";
import { ReactComponent as SearchNormal } from "../../assets/icons/search-normal.svg";
import { ReactComponent as LeftPaging } from "../../assets/icons/left-paging.svg";
import { ReactComponent as RightPaging } from "../../assets/icons/right-paging.svg";
import { ReactComponent as LeftPaging2 } from "../../assets/icons/left-paging2.svg";
import { ReactComponent as RightPaging2 } from "../../assets/icons/right-paging2.svg";
import { ReactComponent as Export } from "../../assets/icons/export.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as Password } from "../../assets/icons/password.svg";
import { ReactComponent as IDCard } from "../../assets/icons/id-card.svg";
import { ReactComponent as Membership } from "../../assets/icons/membership.svg";
import { ReactComponent as Mail2 } from "../../assets/icons/mail.svg";
import { ReactComponent as FaceId } from "../../assets/icons/face-id.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import { ReactComponent as ImageFill } from "../../assets/icons/image-fill.svg";
import { ReactComponent as PlusFill } from "../../assets/icons/plus-fill.svg";
import { ReactComponent as Dislike } from "../../assets/icons/dislike.svg";
import { ReactComponent as Like } from "../../assets/icons/like.svg";
import { ReactComponent as Info } from "../../assets/icons/info.svg";
import { ReactComponent as Verified } from "../../assets/icons/verified.svg";
import { ReactComponent as LocationFill } from "../../assets/icons/location-fill.svg";
import { ReactComponent as PlayFill } from "../../assets/icons/play-fill.svg";
import { ReactComponent as Club } from "../../assets/icons/club.svg";
import { ReactComponent as Woman } from "../../assets/icons/woman.svg";
import { ReactComponent as Palette } from "../../assets/icons/palette.svg";
import { ReactComponent as Profile2User } from "../../assets/icons/profile-2user.svg";
import { ReactComponent as Sport } from "../../assets/icons/sport.svg";
import { ReactComponent as Shell } from "../../assets/icons/shell.svg";
import { ReactComponent as Plane } from "../../assets/icons/plane.svg";
import { ReactComponent as MusicPlaylist } from "../../assets/icons/music-playlist.svg";
import { ReactComponent as lightbulb2 } from "../../assets/icons/lightbulb2.svg";
import { ReactComponent as MaiComponent } from "../../assets/icons/main-component.svg";
import { ReactComponent as HeartHand } from "../../assets/icons/heart-hand.svg";
import { ReactComponent as PawPrint } from "../../assets/icons/paw-print.svg";
import { ReactComponent as Teacher } from "../../assets/icons/teacher.svg";
import { ReactComponent as PartyPopper } from "../../assets/icons/party-popper.svg";
import { ReactComponent as HeartFull } from "../../assets/icons/heart-full.svg";
import { ReactComponent as CategoryIcon } from "../../assets/icons/category-icon.svg";

import {
  BsChevronLeft,
  BsChevronRight,
  BsCircle,
  BsFillPlusCircleFill,
  BsCheck2,
  BsCameraFill,
  BsFillCircleFill,
  BsQuestionCircleFill,
  BsHeadset,
  BsSend,
  BsCheckSquare,
  BsSquare,
  BsClock,
  BsTelephone,
} from "react-icons/bs";
import { VscNotebook } from "react-icons/vsc";
import { FaRegCircleDot, FaChevronDown, FaKey, FaIdCard, FaFileInvoice } from "react-icons/fa6";
import {
  HiPlus,
  HiMinus,
  HiLightBulb,
  HiOutlineSwitchHorizontal,
  HiDotsVertical,
} from "react-icons/hi";
import { HiXMark } from "react-icons/hi2";
import { MdMail, MdCardMembership } from "react-icons/md";
import {
  FaRegEdit,
  FaStar,
  FaRegStar,
  FaBell,
  FaCalendar,
  FaMapMarkerAlt,
  FaUserAlt,
  FaRegUser,
} from "react-icons/fa";
import { RiHeartsFill, RiHandCoinFill, RiLoginBoxLine } from "react-icons/ri";
import { IoIosMailOpen } from "react-icons/io";
import { SiAdguard } from "react-icons/si";
import { TiInfoLarge } from "react-icons/ti";
import { VscMail } from "react-icons/vsc";
import { FiLock } from "react-icons/fi";
import { TbCurrencyDollar } from "react-icons/tb";
import clsx from "clsx";

export const FbSvg = Fb;
export const InsSvg = Ins;
export const TwiSvg = Twi;
export const CertSvg = Cert;
export const HambugerSvg = Hambuger;
export const SearchSvg = Search;
export const IconHeartSvg = IconHeart;
export const LeftButtonSvg = LeftButton;
export const RightButtonSvg = RightButton;
export const RocketSvg = Rocket;
export const FireSvg = Fire;
export const RoundedUpSvg = RoundedUp;
export const LinkSvg = Link;
export const ImageSvg = Image;
export const ShareSvg = Share;
export const HeartOutlineSvg = HeartOutline;
export const LogoSvg = Logo;
export const NotificationSvg = Notification;
export const ArrowDownSvg = ArrowDown;
export const CalendarIconSvg = CalendarIcon;
export const ClockIconSvg = ClockIcon;
export const DollarCircleSvg = DollarCircle;
export const EditSvg = Edit;
export const GlobalSvg = Global;
export const ProfileSvg = Profile;
export const SendSvg = SendIcon;
export const PaperclipSvg = Paperclip;
export const ThreeDotSvg = ThreeDot;
export const ArrowRightSvg = ArrowRight;
export const ArrowLeftSvg = ArrowLeft;
export const ThreeSquareSvg = ThreeSquare;
export const ChefHatSvg = ChefHat;
export const DrinkSvg = Drink;
export const FilterSvg = Filter;
export const HandPlantSvg = HandPlant;
export const ListSvg = List;
export const LovelySvg = Lovely;
export const MapSvg = Map;
export const MountainSnowSvg = MountainSnow;
export const CloseSquareSvg = CloseSquare;
export const CalendarFieldSvg = CalendarField;
export const LocationSvg = Location;
export const RadioCheckedSvg = RadioChecked;
export const RadioUncheckSvg = RadioUncheck;
export const ArrowSwapSvg = ArrowSwap;
export const UploadCloudSvg = UploadCloud;
export const FacebookSvg = Facebook;
export const InstagramSvg = Instagram;
export const LinkedinSvg = Linkedin;
export const SnapchatSvg = Snapchat;
export const TwitterSvg = Twitter;
export const YoutubeSvg = Youtube;
export const EditUnderlineSvg = EditUnderline;
export const PlusStaticSvg = PlusStatic;
export const LightBulbSvg = LightBulb;
export const TickCircleSvg = TickCircle;
export const Calendar2Svg = Calendar2;
export const ChartSvg = Chart;
export const MsgNotifSvg = MsgNotif;
export const MoreSvg = More;
export const AnnouncementSvg = Announcement;
export const TrashSvg = Trash;
export const CloseCircleSvg = CloseCircle;
export const CloseCircle2Svg = CloseCircle2;
export const QuestionCircleSvg = QuestionCircle;
export const SmsEditSvg = SmsEdit;
export const CalendarEditSvg = CalendarEdit;
export const AddSquareSvg = AddSquare;
export const MinusSquareSvg = MinusSquare;
export const AddCircleSvg = AddCircle;
export const DirectUpSvg = DirectUp;
export const Message2Svg = Message2;
export const SmsSvg = Sms;
export const CallSvg = Call;
export const DocumentTextSvg = DocumentText;
export const ElementSvg = Element;
export const HomeSvg = Home;
export const LogoutSvg = Logout;
export const MoneySvg = Money;
export const SettingSvg = Setting;
export const Star2Svg = Star2;
export const Star3Svg = Star3;
export const SearchNormalSvg = SearchNormal;
export const LeftPagingSvg = LeftPaging;
export const RightPagingSvg = RightPaging;
export const LeftPaging2Svg = LeftPaging2;
export const RightPaging2Svg = RightPaging2;
export const ExportSvg = Export;
export const UserSvg = User;
export const PasswordSvg = FaKey;
export const IDCardSvg = FaIdCard;
export const MembershipSvg = MdCardMembership;
export const FaFileInvoiceSvg = FaFileInvoice;
export const Mail2Svg = Mail2;
export const FaceIdSvg = FaceId;
export const PhoneSvg = Phone;
export const ImageFillSvg = ImageFill;
export const PlusFillSvg = PlusFill;
export const DislikeSvg = Dislike;
export const LikeSvg = Like;
export const InfoSvg = Info;
export const VerifiedSvg = Verified;
export const LocationFillSvg = LocationFill;
export const PlayFillSvg = PlayFill;
export const ClubSvg = Club;
export const WomanSvg = Woman;
export const PaletteSvg = Palette;
export const Profile2UserSvg = Profile2User;
export const SportSvg = Sport;
export const ShellSvg = Shell;
export const PlaneSvg = Plane;
export const MusicPlaylistSvg = MusicPlaylist;
export const Lightbulb2Svg = lightbulb2;
export const MaiComponentSvg = MaiComponent;
export const HeartHandSvg = HeartHand;
export const PawPrintSvg = PawPrint;
export const TeacherSvg = Teacher;
export const PartyPopperSvg = PartyPopper;
export const HeartFullSvg = HeartFull;
export const CategoryIconSvg = CategoryIcon;

export const Minus = (props: { className?: string }) => <HiMinus {...props} />;

export const Plus = (props: { className?: string }) => <HiPlus {...props} />;

export const ChevronDown = (props: { className?: string }) => <FaChevronDown {...props} />;

export const Book = (props: { className?: string }) => <VscNotebook {...props} />;

export const ChevronRight = (props: { className?: string }) => <BsChevronRight {...props} />;

export const ChevronCircleRight = ({ className, ...props }: { className?: string }) => (
  <BsChevronRight {...props} className={clsx("rounded-full border box-border p-2", className)} />
);

export const ChevronCircleLeft = ({ className, ...props }: { className?: string }) => (
  <BsChevronLeft {...props} className={clsx("rounded-full border box-border p-2", className)} />
);

export const CircleDot = (props: { className?: string }) => <FaRegCircleDot {...props} />;

export const Circle = (props: { className?: string }) => <BsCircle {...props} />;

export const Lightbulb = (props: { className?: string }) => <HiLightBulb {...props} />;

export const FillPlusCircleFill = (props: { className?: string }) => (
  <BsFillPlusCircleFill {...props} />
);

export const Mail = (props: { className?: string }) => <MdMail {...props} />;
export const MailOutline = (props: { className?: string }) => <VscMail {...props} />;

export const RegEdit = (props: { className?: string }) => <FaRegEdit {...props} />;

export const Check2 = (props: { className?: string }) => <BsCheck2 {...props} />;

export const Star = (props: { className?: string }) => <FaStar {...props} />;
export const RegStar = (props: { className?: string }) => <FaRegStar {...props} />;

export const HeartsFill = (props: { className?: string }) => <RiHeartsFill {...props} />;

export const CameraFill = (props: { className?: string }) => <BsCameraFill {...props} />;

export const FillCircleFill = (props: { className?: string }) => <BsFillCircleFill {...props} />;

export const QuestionCircleFill = (props: { className?: string }) => (
  <BsQuestionCircleFill {...props} />
);
export const Headset = (props: { className?: string }) => <BsHeadset {...props} />;
export const Bell = (props: { className?: string }) => <FaBell {...props} />;
export const Calendar = (props: { className?: string }) => <FaCalendar {...props} />;
export const HandCoinFill = (props: { className?: string }) => <RiHandCoinFill {...props} />;
export const LoginBoxLine = (props: { className?: string }) => <RiLoginBoxLine {...props} />;
export const IosMailOpen = (props: { className?: string }) => <IoIosMailOpen {...props} />;
export const Adguard = (props: { className?: string }) => <SiAdguard {...props} />;
export const InfoLarge = (props: { className?: string }) => <TiInfoLarge {...props} />;
export const OutlineSwitchHorizontal = (props: { className?: string }) => (
  <HiOutlineSwitchHorizontal {...props} />
);
export const Lock = (props: { className?: string }) => <FiLock {...props} />;
export const MapMarkerAlt = (props: { className?: string }) => <FaMapMarkerAlt {...props} />;
export const Send = (props: { className?: string }) => <BsSend {...props} />;
export const DotsVertical = (props: { className?: string }) => <HiDotsVertical {...props} />;
export const OutlineXMark = (props: { className?: string }) => <HiXMark {...props} />;
export const CheckSquare = (props: { className?: string }) => <BsCheckSquare {...props} />;
export const Square = (props: { className?: string }) => <BsSquare {...props} />;
export const Clock = (props: { className?: string }) => <BsClock {...props} />;
export const CurrencyDollar = (props: { className?: string }) => <TbCurrencyDollar {...props} />;
export const UserAlt = (props: { className?: string }) => <FaUserAlt {...props} />;
export const Telephone = (props: { className?: string }) => <BsTelephone {...props} />;
export const RegUser = (props: { className?: string }) => <FaRegUser {...props} />;
