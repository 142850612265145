import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
} from "chart.js";
import ReduxProvider from "./store/provider";
import { AdminLayout, Inform, Geolocation } from "@app/components/common";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-circular-progressbar/dist/styles.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "suneditor/dist/css/suneditor.min.css";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { googleAuthClientId } from "./config";

import {
  PrivateLayout,
  CreatorPrivateLayout,
  UserPrivateLayout,
  PublicLayout,
  CommonLayout,
  FullwidthLayout,
} from "./components/common";
import { deleteApp } from "firebase/app";

import ErrorPage from "./app/error-page";
import NoPermissionPage from "./app/no-permission-page";
import AboutUs from "./app/about-us";
import Analytics from "./app/analytics";
import BlistBoard from "./app/blist-board";
import CreatorCalendar from "./app/creator-calendar";
import UserCalendar from "./app/user-calendar";
import Category from "./app/category";
import ContactUs from "./app/contact-us";
import CreateExperience from "./app/create-experience";
import Experiences from "./app/experiences";
import ExperienceDetail from "./app/experiences-detail";
import Favorite from "./app/favorite";
import Help from "./app/help";
import SafetyPage from "./app/safety";
import TrackingPage from "./app/tracking-user";
import Messages from "./app/messages";
import Signin from "./app/signin";
import Transaction from "./app/transaction";
import User from "./app/user";
import UserDetail from "./app/user-detail";
import Signup from "./app/signup";
import ForgotPassword from "./app/forgot-password";
import ResetPassword from "./app/reset-password";
import CreatorDashboard from "./app/creator-dashboard";
import HomePage from "./app/home";
import ResultPage from "./app/result";

import AccountProfile from "./app/account-detail/profile";
import AccountPassword from "./app/account-detail/password";
import AccountMembership from "./app/account-detail/membership";
import AccountVerifyCard from "./app/account-detail/verify-card";
import AccountInvoices from "./app/account-detail/invoices";

import AdminDashboard from "./app/admin/dashboard.admin";
import AdminExperiences from "./app/admin/experiences.admin";
import AdminBlistBoard from "./app/admin/blist-board.admin";
import AdminNewsAction from "./app/admin/news-action.admin";
import AdminCategory from "./app/admin/category.admin";
import AdminFeedback from "./app/admin/feedback.admin";
import AdminUser from "./app/admin/user.admin";
import AdminUserActionScreen from "./app/admin/user-action.admin";
import WishlistAction from "./app/admin/wishlist-action.admin";
import CategoryAction from "./app/admin/category-action.admin";
import AdminMessage from "./app/admin/message.admin";
import ExperienceAction from "./app/admin/experience-action.admin";
import AdminInvoice from "./app/admin/invoice.admin";
import AdminTopicScreen from "./app/admin/topic.admin";
import AdminTopicActionScreen from "./app/admin/topic-action.admin";
import AdminPasswordScreen from "./app/admin/password.admin";

import RequestToBook from "./app/request-to-book";
import RequestAndInquiries from "./app/request-inquiries";
import CreateApprenticeProfile from "./app/create-apprentice-profile";
import ApprenticeProfile from "./app/apprentice-profile";
import Review from "./app/review";
import PrivacyPolicy from "./app/privacy-policy";
import TermCondition from "./app/term-condition";
import NewsDetail from "./app/news-detail";
import { useEffect } from "react";
import { app } from "./utils";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler);

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: (
      <CommonLayout>
        <Outlet />
      </CommonLayout>
    ),
    children: [
      {
        path: "",
        element: <Category />,
      },
      {
        path: "wishlist",
        element: <BlistBoard />,
      },
      {
        path: "experiences",
        element: <Experiences />,
      },
      {
        path: "experiences/:experienceId",
        element: <ExperienceDetail />,
      },
      {
        path: "user",
        element: <User />,
      },
      {
        path: "user/:userId",
        element: <UserDetail />,
      },
      {
        path: "safety/:bookingId",
        element: <SafetyPage />,
      },
      {
        path: "tracking/:bookingId",
        element: <TrackingPage />,
      },
      {
        path: "home",
        element: <HomePage />,
      },
      {
        path: "apprentice-profile/:apprenticeId",
        element: <ApprenticeProfile />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "terms-conditions",
        element: <TermCondition />,
      },
      {
        path: "news/:newsId",
        element: <NewsDetail />,
      },
      {
        path: "no-permission",
        element: <NoPermissionPage />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: (
      <FullwidthLayout>
        <Outlet />
      </FullwidthLayout>
    ),
    children: [
      {
        path: "about-us",
        element: <AboutUs />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: (
      <PublicLayout>
        <Outlet />
      </PublicLayout>
    ),
    children: [
      {
        path: "signin",
        element: <Signin />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: (
      <PrivateLayout>
        <Outlet />
      </PrivateLayout>
    ),
    children: [
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "favorite",
        element: <Favorite />,
      },
      {
        path: "get-help",
        element: <Help />,
      },
      {
        path: "messages",
        element: <Messages />,
      },
      {
        path: "transaction",
        element: <Transaction />,
      },
      {
        path: "create-apprentice-profile",
        element: <CreateApprenticeProfile />,
      },
      {
        path: "review/:bookingId",
        element: <Review />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: (
      <CreatorPrivateLayout>
        <Outlet />
      </CreatorPrivateLayout>
    ),
    children: [
      {
        path: "result",
        element: <ResultPage />,
      },
      {
        path: "creator/calendar",
        element: <CreatorCalendar />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "create-experience/:business",
        element: <CreateExperience />,
      },
      {
        path: "update-experience/:experienceId",
        element: <CreateExperience />,
      },
      {
        path: "request-inquiries",
        element: <RequestAndInquiries />,
      },
      {
        path: "creator-dashboard",
        element: <CreatorDashboard />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: (
      <UserPrivateLayout>
        <Outlet />
      </UserPrivateLayout>
    ),
    children: [
      {
        path: "request-to-book/:bookingId",
        element: <RequestToBook />,
      },
      {
        path: "user/calendar",
        element: <UserCalendar />,
      },
    ],
  },
  {
    path: "/account",
    errorElement: <ErrorPage />,
    element: (
      <PrivateLayout>
        <Outlet />
      </PrivateLayout>
    ),
    children: [
      {
        path: "profile",
        element: <AccountProfile />,
      },
      {
        path: "password",
        element: <AccountPassword />,
      },
      {
        path: "membership",
        element: <AccountMembership />,
      },
      {
        path: "verify-card",
        element: <AccountVerifyCard />,
      },
      {
        path: "invoices",
        element: <AccountInvoices />,
      },
    ],
  },
  {
    path: "/admin",
    errorElement: <ErrorPage />,
    element: (
      <AdminLayout>
        <Outlet />
      </AdminLayout>
    ),
    children: [
      {
        path: "",
        element: <AdminDashboard />,
      },
      {
        path: "category",
        element: <AdminCategory />,
      },
      {
        path: "feedback",
        element: <AdminFeedback />,
      },
      {
        path: "experiences",
        element: <AdminExperiences />,
      },
      {
        path: "experiences/update/:id",
        element: <ExperienceAction />,
      },
      {
        path: "blist-board",
        element: <AdminBlistBoard />,
      },
      {
        path: "wishlist/update/:id",
        element: <WishlistAction />,
      },
      {
        path: "news/add",
        element: <AdminNewsAction />,
      },
      {
        path: "news/update/:id",
        element: <AdminNewsAction />,
      },
      {
        path: "user",
        element: <AdminUser />,
      },
      {
        path: "user/add",
        element: <AdminUserActionScreen />,
      },
      {
        path: "category/add",
        element: <CategoryAction />,
      },
      {
        path: "category/update/:id",
        element: <CategoryAction />,
      },
      {
        path: "message",
        element: <AdminMessage />,
      },
      {
        path: "invoice",
        element: <AdminInvoice />,
      },
      {
        path: "topic",
        element: <AdminTopicScreen />,
      },
      {
        path: "topic/add",
        element: <AdminTopicActionScreen />,
      },
      {
        path: "topic/update/:id",
        element: <AdminTopicActionScreen />,
      },
      {
        path: "change-password",
        element: <AdminPasswordScreen />,
      },
    ],
  },
]);

function App() {
  const unloadCallback = () => {
    deleteApp(app);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  return (
    <GoogleOAuthProvider clientId={googleAuthClientId}>
      <ReduxProvider>
        <Inform />
        <Geolocation />
        <RouterProvider router={router} />
      </ReduxProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
